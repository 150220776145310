import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://127.0.0.1:8000/api',
  baseURL: 'https://api.verenatransgold.com/api',
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json, multipart/form-data',
  },
})

// Add a request interceptor to set headers from LocalStorage
axiosIns.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, error => Promise.reject(error))

Vue.prototype.$http = axiosIns

export default axiosIns
